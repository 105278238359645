import { useEffect, React } from 'react';
import Page from './components/js/Page'


function App() {
    
    // useEffect(() => {
    //     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    // }, []);
    

    return (
        <div>
            <Page />
        </div>
    );
}

export default App;
